const thankYouSection = document.querySelector('.thank-you');

if (thankYouSection) {
  // const urlParams = new URLSearchParams(window.location.search);
  // const formParam = urlParams.get('form');

  // window.gtag('event', formParam ?? 'unknown');
  window.fbq('track', 'Lead');

  window.open('https://t.me/peterson_trade_bot', '_self');
}
