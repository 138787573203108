import intlTelInput from 'intl-tel-input/build/js/intlTelInputWithUtils';
const { api } = require('../../libs/axios.lib');
const { notify } = require('../../utils/notify.utils');

const startEducationForm = document.querySelector('.start-education-form');

if (startEducationForm) {
  const startEducationFormBtn = startEducationForm.querySelector(
    '#submit-start-education'
  );
  const nameInput = startEducationForm.querySelector('#education-name');
  const phoneInput = startEducationForm.querySelector('#education-phone');

  startEducationFormBtn.addEventListener('click', async (e) => {
    e.preventDefault();
    try {
      const iti = intlTelInput.getInstance(phoneInput);
      startEducationFormBtn.classList.add('is-loading');

      const name = nameInput.value;
      const phone = iti.getNumber();

      // const foundContacts = await api.get(`/contact?query=${phone}`);
      // if (foundContacts?.data) {
      //   console.log('Contact allready exists!');
      //   notify('Contact allready exists!', 'error');
      //   throw new Error('Contact allready exists!');
      // }

      const newContact = await api.post('/contact', {
        name,
        phone,
      });

      const newLead = await api.post('/lead', {
        name: 'Free education',
        phone,
        pipeline_id: 8243187,
        link: window.location.href,
      });

      await api.post('/lead-contact-link', {
        lead_id: newLead.data._embedded.leads[0].id,
        contact_id: newContact.data._embedded.contacts[0].id,
      });

      await api.post('/lead/user-data', {
        lead_id: newLead.data._embedded.leads[0].id,
        link: window.location.href,
        name: name,
        phone: phone,
        tariff: '-',
      });

      nameInput.value = '';
      phoneInput.value = '';

      // window.gtag('event', 'free-education', {
      //   phone,
      //   name,
      // });

      notify('Request sent successfully!', 'info');

      window.open('/thank-you?form=free_education', '_self');
    } catch (error) {
      console.log(error);
      notify('Error!', JSON.stringify(error));
    } finally {
      startEducationFormBtn.classList.remove('is-loading');
    }
  });
}
